var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-article"
  }, [_c('div', {
    staticClass: "left base-container"
  }, [_c('el-tabs', {
    attrs: {
      "before-leave": _vm.handleTabSwitch
    },
    model: {
      value: _vm.formData.articleContentType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "articleContentType", $$v);
      },
      expression: "formData.articleContentType"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "label": "图文",
      "name": "1",
      "disabled": _vm.formData.articleContentType != 1 && _vm.formData.articleId ? true : false
    }
  }, [_c('div', {
    staticClass: "image-text"
  }, [_c('el-input', {
    staticClass: "title",
    attrs: {
      "maxlength": "50",
      "placeholder": "请输入文章标题（5-50个汉字）"
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  }), _c('f-editor', {
    ref: "editor",
    attrs: {
      "defaultContent": _vm.defaultContent
    },
    on: {
      "on-change": _vm.editorChange
    }
  })], 1)]), _c('el-tab-pane', {
    attrs: {
      "label": "公众号",
      "name": "2",
      "disabled": _vm.formData.articleContentType != 2 && _vm.formData.articleId ? true : false
    }
  }, [_c('div', {
    staticClass: "image-text"
  }, [_c('el-input', {
    staticClass: "title",
    attrs: {
      "placeholder": "请输入文章标题（5-50个汉字）",
      "maxlength": "50"
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  }), _c('el-input', {
    staticClass: "title",
    staticStyle: {
      "border-top": "1px solid #eee"
    },
    attrs: {
      "placeholder": "请输入文章摘要（10-50个汉字）",
      "maxlength": "50"
    },
    model: {
      value: _vm.formData.summary,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "summary", $$v);
      },
      expression: "formData.summary"
    }
  }), _c('div', {
    staticClass: "web-view"
  }, [_c('svg-icon', {
    staticClass: "icon",
    attrs: {
      "icon-class": "link"
    }
  }), _c('el-input', {
    staticClass: "title",
    attrs: {
      "placeholder": "已关联当前小程序的公众号文章链接地址"
    },
    model: {
      value: _vm.formData.weLink,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "weLink", $$v);
      },
      expression: "formData.weLink"
    }
  })], 1)], 1)])], 1)], 1), _c('div', {
    staticClass: "right base-container"
  }, [_c('div', {
    staticClass: "cover"
  }, [_vm._m(0), _c('p', {
    staticClass: "tips"
  }, [_vm._v(" 优质的封面有利于吸引用户关注，最佳尺寸800px x 640px，或5:4比例图片 ")]), _c('f-upload', {
    attrs: {
      "type": 2,
      "isCrop": "",
      "default-file-list": [{
        url: _vm.formData.coverImagePath
      }]
    },
    on: {
      "on-success": _vm.coverUploadSuccess
    }
  })], 1), !_vm.isEstateArticle ? _c('div', {
    staticClass: "rel-user mt30"
  }, [_vm._m(1), _c('el-autocomplete', {
    staticStyle: {
      "width": "260px"
    },
    attrs: {
      "prefix-icon": "el-icon-search",
      "value-key": "name",
      "fetch-suggestions": _vm.querySearchAsync,
      "hide-loading": _vm.hideFetchLoading,
      "placeholder": "请输入"
    },
    on: {
      "select": _vm.handleUserSelect
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return _c('div', {
          staticClass: "auth-user-option"
        }, [_c('el-image', {
          staticClass: "avatar",
          attrs: {
            "fit": "cover",
            "src": item.authAvatarPath
          }
        }, [_c('div', {
          attrs: {
            "slot": "error"
          },
          slot: "error"
        }, [_c('img', {
          attrs: {
            "src": _vm.$defaultAvatarUrl
          }
        })])]), _c('div', {
          staticClass: "name-wrap"
        }, [_c('p', [_vm._v(_vm._s(item.authName))]), _c('p', {
          staticClass: "sum"
        }, [_vm._v(_vm._s(item.description))])])], 1);
      }
    }], null, false, 3174274349),
    model: {
      value: _vm.searchUserKeyword,
      callback: function callback($$v) {
        _vm.searchUserKeyword = $$v;
      },
      expression: "searchUserKeyword"
    }
  }), _vm.selectedUser ? _c('div', {
    staticClass: "selected-user"
  }, [_c('el-image', {
    staticClass: "cover",
    attrs: {
      "src": _vm.selectedUser.authAvatarPath
    }
  }), _c('div', {
    staticClass: "name-wrap"
  }, [_c('p', [_vm._v(_vm._s(_vm.selectedUser.authName))]), _c('p', {
    staticClass: "sum"
  }, [_vm._v(_vm._s(_vm.selectedUser.description))])]), _c('el-button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": _vm.delSelectedUser
    }
  }, [_vm._v("删除")])], 1) : _vm._e(), _c('div', {
    staticClass: "rel-estates mt30"
  }, [_vm._m(2), _c('el-button', {
    staticClass: "add-btn",
    attrs: {
      "icon": "el-icon-plus"
    },
    on: {
      "click": function click($event) {
        _vm.toggleChooseEstateDialog = true;
      }
    }
  }, [_vm._v("选择添加")])], 1)], 1) : _vm._e(), !_vm.isEstateArticle ? _c('div', {
    staticClass: "rel-estates"
  }, _vm._l(_vm.selectedEstates, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "selected-estate"
    }, [_c('el-image', {
      staticClass: "cover",
      attrs: {
        "src": item.estateImage
      }
    }), _c('div', {
      staticClass: "name-wrap"
    }, [_c('p', [_vm._v(_vm._s(item.estateName))]), _c('p', {
      staticClass: "sum"
    }, [_vm._v(_vm._s(item.regionName))])]), _c('el-button', {
      attrs: {
        "type": "text"
      },
      on: {
        "click": function click($event) {
          return _vm.delSelectedEstate(index);
        }
      }
    }, [_vm._v("删除")])], 1);
  }), 0) : _vm._e()]), _c('div', {
    staticClass: "foot-fix-bar x1"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        return _vm.submit(0);
      }
    }
  }, [_vm._v("存草稿")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.submitLoading
    },
    on: {
      "click": function click($event) {
        return _vm.submit(1);
      }
    }
  }, [_vm._v("发布")])], 1), _c('choose-estate-dialog', {
    ref: "chooseEstateDialog",
    attrs: {
      "title": "添加楼盘",
      "visible": _vm.toggleChooseEstateDialog
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleChooseEstateDialog = $event;
      },
      "select": _vm.selectEstates
    }
  }), _c('el-button', {
    staticClass: "back-btn",
    attrs: {
      "type": "text"
    },
    on: {
      "click": _vm.resetForm
    }
  }, [_vm._v("返回文章列表")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "label"
  }, [_vm._v("封面图"), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "label"
  }, [_vm._v("关联认证用户"), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "label"
  }, [_vm._v(" 关联楼盘"), _c('span', {
    staticClass: "sub"
  }, [_vm._v("（选填，可关联多个楼盘）")])]);

}]

export { render, staticRenderFns }