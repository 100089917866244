<template>
  <div class="add-article">
    <div class="left base-container">
      <el-tabs
        v-model="formData.articleContentType"
        :before-leave="handleTabSwitch"
      >
        <el-tab-pane
          label="图文"
          name="1"
          :disabled="
            formData.articleContentType != 1 && formData.articleId
              ? true
              : false
          "
        >
          <div class="image-text">
            <el-input
              class="title"
              maxlength="50"
              v-model="formData.title"
              placeholder="请输入文章标题（5-50个汉字）"
            ></el-input>
            <f-editor
              @on-change="editorChange"
              :defaultContent="defaultContent"
              ref="editor"
            ></f-editor>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="公众号"
          name="2"
          :disabled="
            formData.articleContentType != 2 && formData.articleId
              ? true
              : false
          "
        >
          <div class="image-text">
            <el-input
              class="title"
              v-model="formData.title"
              placeholder="请输入文章标题（5-50个汉字）"
              maxlength="50"
            ></el-input>
            <el-input
              style="border-top:1px solid #eee"
              class="title"
              v-model="formData.summary"
              placeholder="请输入文章摘要（10-50个汉字）"
              maxlength="50"
            ></el-input>
            <div class="web-view">
              <svg-icon class="icon" icon-class="link"></svg-icon>
              <el-input
                v-model="formData.weLink"
                class="title"
                placeholder="已关联当前小程序的公众号文章链接地址"
              ></el-input>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="right base-container">
      <div class="cover">
        <div class="label">封面图<span class="red">*</span></div>
        <p class="tips">
          优质的封面有利于吸引用户关注，最佳尺寸800px x 640px，或5:4比例图片
        </p>
        <f-upload
          :type="2"
          isCrop
          :default-file-list="[{ url: formData.coverImagePath }]"
          @on-success="coverUploadSuccess"
        ></f-upload>
      </div>
      <div class="rel-user mt30" v-if="!isEstateArticle">
        <div class="label">关联认证用户<span class="red">*</span></div>
        <el-autocomplete
          style="width:260px"
          prefix-icon="el-icon-search"
          value-key="name"
          v-model="searchUserKeyword"
          :fetch-suggestions="querySearchAsync"
          :hide-loading="hideFetchLoading"
          placeholder="请输入"
          @select="handleUserSelect"
        >
          <div class="auth-user-option" slot-scope="{ item }">
            <el-image class="avatar" fit="cover" :src="item.authAvatarPath">
              <div slot="error">
                <img :src="$defaultAvatarUrl" />
              </div>
            </el-image>
            <div class="name-wrap">
              <p>{{ item.authName }}</p>
              <p class="sum">{{ item.description }}</p>
            </div>
          </div>
        </el-autocomplete>
        <div class="selected-user" v-if="selectedUser">
          <el-image class="cover" :src="selectedUser.authAvatarPath"></el-image>
          <div class="name-wrap">
            <p>{{ selectedUser.authName }}</p>
            <p class="sum">{{ selectedUser.description }}</p>
          </div>
          <el-button type="text" @click="delSelectedUser">删除</el-button>
        </div>
        <div class="rel-estates mt30">
          <div class="label">
            关联楼盘<span class="sub">（选填，可关联多个楼盘）</span>
          </div>
          <el-button
            class="add-btn"
            icon="el-icon-plus"
            @click="toggleChooseEstateDialog = true"
            >选择添加</el-button
          >
        </div>
      </div>
      <div class="rel-estates" v-if="!isEstateArticle">
        <div
          class="selected-estate"
          v-for="(item, index) in selectedEstates"
          :key="index"
        >
          <el-image class="cover" :src="item.estateImage"></el-image>
          <div class="name-wrap">
            <p>{{ item.estateName }}</p>
            <p class="sum">{{ item.regionName }}</p>
          </div>
          <el-button type="text" @click="delSelectedEstate(index)"
            >删除</el-button
          >
        </div>
      </div>
    </div>
    <div class="foot-fix-bar x1">
      <el-button @click="submit(0)">存草稿</el-button>
      <el-button type="primary" @click="submit(1)" :loading="submitLoading"
        >发布</el-button
      >
    </div>
    <choose-estate-dialog
      ref="chooseEstateDialog"
      title="添加楼盘"
      :visible.sync="toggleChooseEstateDialog"
      @select="selectEstates"
    ></choose-estate-dialog>
    <el-button type="text" class="back-btn" @click="resetForm"
      >返回文章列表</el-button
    >
  </div>
</template>

<script>
import FEditor from "@/components/editor/index";
import FUpload from "@/components/upload";
import ChooseEstateDialog from "@/components/estate/chooseDialog";
import rules from "@/utils/rules";
import {
  getAuthUserList,
  insertArticle,
  editArticle,
  getArticleDetail
} from "@/api/appManage";
import { getByteLen } from "@/utils/index";

const formData = {
  articleContentType: "1", //文章内容类型:1.图文,2.公众号 ,
  articleId: null, //文章id ,
  articleType: "3", //文章类型:1.项目,2.房易宝运营平台,3.聚合平台 ,
  customerAuthId: null, //关联认证用户 ,
  content: "",
  weLink: "", //公众号链接【 前端用】
  coverImagePath: "",
  summary: "", //文章摘要
  estateIdList: [], //关联楼盘id列表
  status: "", //0:草稿,1:发布,2:下架 ,
  title: ""
};
export default {
  components: {
    FEditor,
    FUpload,
    ChooseEstateDialog
  },
  props: {
    aritcleId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      submitLoading: false, //提交按钮的loading
      isEstateArticle: false,
      defaultContent: "",
      searchUserKeyword: "",
      toggleChooseEstateDialog: false,
      hideFetchLoading: false,
      formData: JSON.parse(JSON.stringify(formData)),
      selectedEstates: [],
      selectedUser: null //当前选择的关联用户
    };
  },
  watch: {
    aritcleId: {
      immediate: true,
      handler(val) {
        if (val) {
          if (typeof val == "string" && val.includes("refresh")) {
            return;
          }
          this.getArticleDetail(val);
        }
      }
    }
  },
  created() {
    if (this.$route.path == "/estate/dictionary/article") {
      this.formData.articleType = "1";
      this.isEstateArticle = true;
    }
  },
  methods: {
    handleTabSwitch() {
      //切换tab
      if (this.formData.articleId) {
        return true;
      }
      if (
        !this.formData.content &&
        !this.formData.title &&
        !this.formData.summary &&
        !this.formData.weLink
      ) {
        return true;
      }
      return new Promise((resolve, reject) => {
        this.$showConfirm(
          () => {
            this.formData.content = "";
            this.formData.title = "";
            this.formData.weLink = "";
            this.formData.summary = "";
            this.$refs.editor.clear();
            resolve();
          },
          "切换文章类型将清空当前内容，是否继续?",
          "提示",
          () => {
            reject();
          }
        );
      });
    },
    async getArticleDetail(id) {
      //获取文章详情
      const res = await getArticleDetail({ id });
      if (res) {
        this.formData = { ...this.$deepClone(res), weLink: "" };
        this.selectedEstates = res.estateList || [];
        this.formData.estateIdList = this.selectedEstates.map(
          item => item.estateId
        );
        this.formData.articleContentType = String(
          this.formData.articleContentType
        );
        if (this.formData.articleContentType == 1) {
          this.defaultContent = res.content;
        } else {
          this.formData.weLink = res.content;
        }
        this.$refs.chooseEstateDialog.setSelected(
          this.formData.estateIdList,
          res.estateList
        );
        this.selectedUser = {
          authName: res.relationAuthorName,
          authAvatarPath: res.relationAuthorAvatar,
          description: res.description
        };
        delete this.formData.estateList;
      }
    },
    editorChange(data) {
      //编辑器编辑
      this.formData.content = data.html;
    },
    async querySearchAsync(queryString, cb) {
      //查询用户列表
      this.hideFetchLoading = true;
      if (!queryString || !queryString.trim()) return;
      this.hideFetchLoading = false;
      let query = {
        authName: queryString
      };
      let data = await getAuthUserList(query);
      data = data || [];
      this.hideFetchLoading = true;
      cb(data);
    },
    coverUploadSuccess(fileList) {
      //封面图上传成功
      this.formData.coverImagePath = fileList[0].url;
    },
    selectEstates(ids, list) {
      //选择楼盘
      this.formData.estateIdList = ids;
      this.selectedEstates = list;
    },
    async handleUserSelect(item) {
      //选择用户
      this.selectedUser = item;
      this.formData.customerAuthId = item.id;
    },
    delSelectedEstate(index) {
      //删除选择的楼盘
      this.formData.estateIdList.splice(index, 1);
      this.selectedEstates.splice(index, 1);
      this.$refs.chooseEstateDialog.setSelected(this.formData.estateIdList);
    },
    delSelectedUser() {
      //删除选择的用户
      this.selectedUser = null;
      this.formData.customerAuthId = null;
    },
    resetForm() {
      this.formData = this.$deepClone(formData);
      this.selectedEstates = [];
      this.selectedUser = null;
      this.searchUserKeyword = "";
      this.defaultContent = "";
      this.$refs.chooseEstateDialog.setSelected([]);
      this.$refs.editor.clear();
      this.$emit("close");
    },
    async submit(status) {
      //提交
      let msg = "";
      const param = this.$deepClone(this.formData);
      if (getByteLen(param.title) < 9) {
        msg = "文章标题必须为5-30个汉字（一个字等于两个英文字符或特殊字符）";
      } else if (
        getByteLen(param.content) < 40 &&
        param.articleContentType == 1
      ) {
        msg = "文章内容必须20个汉字以上（一个字等于两个英文字符或特殊字符）";
      } else if (
        getByteLen(param.summary) < 10 &&
        param.articleContentType == 2
      ) {
        msg = "文章摘要必须10个汉字以上（一个字等于两个英文字符或特殊字符）";
      } else if (
        param.articleContentType == 2 &&
        !rules.url.pattern.test(param.weLink)
      ) {
        msg = "请输入正确的文章链接地址";
      } else if (!param.coverImagePath) {
        msg = "请上传文章封面图";
      } else if (this.formData.articleType == 3 && !param.customerAuthId) {
        msg = "请选择关联认证用户";
      }
      if (msg) {
        this.$showError(msg);
        return;
      }
      if (param.articleContentType == 2) {
        param.content = param.weLink;
      } else {
        this.submitLoading = true;
        const { content, hasFail } = await this.$refs.editor.uploadAllImg();
        if (hasFail) {
          //如果有图片失败
          this.$showError("请替换或删除上传失败的图片");
          this.submitLoading = false;
          return;
        }
        param.content = content;
      }
      param.status = status;
      delete param.publishDate;
      const fn = param.articleId ? editArticle : insertArticle;
      const res = await fn(param);
      this.submitLoading = false;
      if (res) {
        this.$showSuccess("操作成功");
        this.$emit("complete");
        this.resetForm();
      }
    }
  }
};
</script>
<style lang="scss">
.auth-user-option {
  display: flex;
  height: 50px;
  padding: 5px 0;
  margin: 10px 0;
  .avatar {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
  .name-wrap {
    padding: 0 10px;
    flex: 1;
    font-size: 14px;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .sum {
      color: #999;
    }
  }
}
.add-article {
  min-height: 100%;
  padding-bottom: 80px;
  position: relative;
  display: flex;
  .left {
    flex: 1;
    padding: 20px 0 0 0;
    .el-tabs {
      .el-tabs__active-bar {
        width: 32px;
      }
      .el-tabs__nav-wrap {
        &:after {
          height: 1px;
        }
      }
      .el-tabs__nav {
        margin-left: 20px;
      }
      .el-tabs__item {
        font-size: 16px;
      }
    }
    .el-tabs {
      height: 100%;
      .el-tabs__content {
        height: calc(100% - 80px);
        .el-tab-pane {
          height: 100%;
        }
      }
    }
  }
  .image-text {
    padding-left: 20px;
    .quill-editor {
      height: calc(100vh - 390px);
      max-height: 60vh;
    }
    .el-input__inner {
      height: 80px;
      font-size: 18px;
      line-height: 80px;
      border: 0;
    }
    .ql-container.ql-snow {
      border: 0;
    }
    .ql-toolbar.ql-snow {
      border: 0;
      border-top: 1px solid #eee;
    }
  }
  .right {
    width: 330px;
    padding: 20px 20px 0;
    margin-left: 20px;
    .label {
      padding-bottom: 10px;
      font-size: 16px;
      .sub {
        font-size: 14px;
        color: #999;
      }
    }
    .tips {
      margin-bottom: 10px;
      color: #999;
    }
    .selected-user,
    .selected-estate {
      display: flex;
      height: 70px;
      padding: 15px 10px;
      margin-top: 10px;
      background: #f5f5f5;
      .cover {
        min-width: 40px;
        width: 40px;
        height: 40px;
      }
      .name-wrap {
        padding: 0 10px;
        flex: 1;
        font-size: 14px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .sum {
          color: #999;
        }
      }
    }
    .rel-estates {
      padding-bottom: 20px;
    }
    .selected-user {
      .cover {
        border-radius: 20px;
      }
    }
    .add-btn {
      margin: 10px 0;
      color: $primary;
    }
  }
}
</style>

<style scoped lang="scss">
.back-btn {
  position: fixed;
  left: 380px;
  top: 12px;
  z-index: 900;
}
.mt30 {
  margin-top: 30px;
}
.foot-fix-bar {
  padding: 10px 0;
  position: fixed;
  left: 220px;
  bottom: 0;
  right: 0;
  z-index: 9;
  background: #fff;
  box-shadow: 0 0 5px #eee;
  border-top: 1px solid #eee;
}
.web-view {
  border-top: 1px solid #eee;
  display: flex;
  align-items: center;
  .icon {
    font-size: 20px;
    color: $primary;
  }
}
</style>
