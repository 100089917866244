var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowList,
      expression: "isShowList"
    }],
    staticClass: "base-container"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_vm._m(0), _c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": function click($event) {
        _vm.isShowList = false;
      }
    }
  }, [_vm._v("发布文章")])], 1), _c('div', {
    staticClass: "filter"
  }, [_c('el-input', {
    staticClass: "f-search-bar",
    staticStyle: {
      "width": "320px"
    },
    attrs: {
      "placeholder": "文章标题"
    },
    model: {
      value: _vm.queryData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "title", $$v);
      },
      expression: "queryData.title"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1), _c('el-select', {
    staticClass: "select",
    attrs: {
      "placeholder": "状态",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "status", $$v);
      },
      expression: "queryData.status"
    }
  }, _vm._l(_vm.status, function (item, key) {
    return _c('el-option', {
      key: key,
      attrs: {
        "label": item,
        "value": key
      }
    });
  }), 1), _c('el-select', {
    staticClass: "select",
    attrs: {
      "placeholder": "类型",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.articleContentType,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "articleContentType", $$v);
      },
      expression: "queryData.articleContentType"
    }
  }, [_c('el-option', {
    attrs: {
      "value": "1",
      "label": "图文"
    }
  }), _c('el-option', {
    attrs: {
      "value": "2",
      "label": "公众号"
    }
  })], 1), !_vm.isEstateArticle ? _c('el-autocomplete', {
    staticClass: "select",
    attrs: {
      "prefix-icon": "el-icon-search",
      "value-key": "authName",
      "fetch-suggestions": _vm.querySearchAsync,
      "hide-loading": _vm.hideFetchLoading,
      "placeholder": "关联认证用户"
    },
    on: {
      "select": _vm.handleUserSelect
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return _c('div', {
          staticStyle: {
            "line-height": "34px",
            "padding": "0"
          }
        }, [_vm._v(" " + _vm._s(item.authName) + " ")]);
      }
    }], null, false, 341045725),
    model: {
      value: _vm.queryData.authUserName,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "authUserName", $$v);
      },
      expression: "queryData.authUserName"
    }
  }) : _vm._e()], 1), _c('el-table', {
    staticClass: "body",
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      "border": "",
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "文章ID",
      "prop": "articleId",
      "width": "100px"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "文章",
      "width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "base-info"
        }, [_c('el-image', {
          staticClass: "cover",
          attrs: {
            "fit": "cover",
            "src": scope.row.coverImagePath
          }
        }), _c('div', {
          staticClass: "text"
        }, [_c('p', {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(scope.row.title)), scope.row.top ? _c('span', {
          staticClass: "f-top-badge"
        }, [_vm._v("荐")]) : _vm._e()]), scope.row.relationAuthorName ? _c('div', {
          staticClass: "sub x5"
        }, [_c('el-image', {
          staticClass: "avatar",
          attrs: {
            "src": scope.row.relationAuthorAvatar
          }
        }), _c('p', [_vm._v(_vm._s(scope.row.relationAuthorName))])], 1) : _vm._e()])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', {
          class: {
            red: scope.row.status === 0
          }
        }, [_vm._v(" " + _vm._s(_vm.status[scope.row.status]) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "浏览量",
      "prop": "applyUserName"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-dropdown', {
          ref: 'viewCountDropdown' + scope.$index,
          attrs: {
            "trigger": "click"
          },
          on: {
            "visible-change": function visibleChange() {
              return _vm.curViewCount = scope.row.viewNum;
            }
          }
        }, [_c('el-button', {
          staticClass: "mw10-i",
          attrs: {
            "type": "text"
          }
        }, [_vm._v(" " + _vm._s(scope.row.viewNum) + " ")]), _c('el-dropdown-menu', {
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('div', {
          staticClass: "f-top-dropdown"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("编辑浏览量"), _c('span', {
          staticClass: "red"
        }, [_vm._v("*")])]), _c('el-input', {
          attrs: {
            "maxlength": "7",
            "placeholder": "值越小越靠前"
          },
          model: {
            value: _vm.curViewCount,
            callback: function callback($$v) {
              _vm.curViewCount = _vm._n($$v);
            },
            expression: "curViewCount"
          }
        }), _c('div', {
          staticClass: "foot"
        }, [_c('el-button', {
          attrs: {
            "size": "mini"
          },
          on: {
            "click": function click($event) {
              return _vm.closeViewCountDropdown(scope.$index);
            }
          }
        }, [_vm._v("取消")]), _c('el-button', {
          attrs: {
            "type": "primary",
            "size": "mini"
          },
          on: {
            "click": function click($event) {
              return _vm.editViewCount(scope.row.articleId, scope.$index);
            }
          }
        }, [_vm._v("确定")])], 1)], 1)])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "创建人",
      "prop": "createUserName"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "创建时间",
      "prop": "inDate",
      "width": "200px"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "230px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showEdit(scope.row.articleId);
            }
          }
        }, [_vm._v("编辑")]), scope.row.status != 1 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.changeArticleStatus(scope.row.articleId, 1);
            }
          }
        }, [_vm._v("发布")]) : _vm._e(), scope.row.status == 1 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.changeArticleStatus(scope.row.articleId, 0);
            }
          }
        }, [_vm._v("下架")]) : _vm._e(), !_vm.isEstateArticle ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.changeArticleTop(scope.row.articleId, !scope.row.top);
            }
          }
        }, [_vm._v(_vm._s(scope.row.top ? "取荐" : "推荐"))]) : _vm._e(), !_vm.isEstateArticle ? _c('el-dropdown', {
          on: {
            "visible-change": function visibleChange(bool) {
              return _vm.getQrCode(scope.row.articleId, bool, scope.row.articleContentType);
            }
          }
        }, [_c('el-button', {
          staticClass: "ml10",
          attrs: {
            "type": "text"
          }
        }, [_vm._v("预览")]), _c('el-dropdown-menu', {
          attrs: {
            "slot": "dropdown",
            "disabled": ""
          },
          slot: "dropdown"
        }, [_c('el-dropdown-item', {
          staticClass: "dropdown-qrcode"
        }, [_c('el-image', {
          staticClass: "img",
          attrs: {
            "fit": "cover",
            "src": _vm.curViewQrCode
          }
        }, [!_vm.curViewQrCode ? _c('i', {
          staticClass: "qrcode-loading el-icon-loading",
          attrs: {
            "slot": "error"
          },
          slot: "error"
        }) : _vm._e()]), _c('p', {
          staticClass: "tips"
        }, [_vm._v(" 请使用微信扫描二维码 ")])], 1)], 1)], 1) : _vm._e(), scope.row.status != 1 ? _c('el-popconfirm', {
          attrs: {
            "title": "确定删除此篇文章吗？"
          },
          on: {
            "onConfirm": function onConfirm($event) {
              return _vm.delArticle(scope.row.articleId);
            }
          }
        }, [_c('el-button', {
          staticClass: "ml10",
          attrs: {
            "slot": "reference",
            "type": "text"
          },
          slot: "reference"
        }, [_vm._v("删除")])], 1) : _vm._e()];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.queryData.pageNum,
      "page-sizes": _vm.pageSizes,
      "page-size": _vm.queryData.pageSize,
      "layout": "sizes, prev, pager, next,jumper",
      "total": _vm.total
    },
    on: {
      "size-change": _vm.pageSizeChange,
      "current-change": _vm.pageNumChange,
      "update:currentPage": function updateCurrentPage($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:current-page": function updateCurrentPage($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      }
    }
  })], 1), !_vm.isShowList ? _c('add-article', {
    class: {
      'add-article-hide': _vm.isShowList
    },
    attrs: {
      "aritcleId": _vm.curAritcleId
    },
    on: {
      "complete": _vm.getArticleList,
      "close": _vm.closeAdd
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("文章管理")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v(" 添加公众号的文章，公众号必须要关联当前小程序，否则小程序端无法查看详情页；"), _c('a', {
    staticClass: "blue",
    attrs: {
      "target": "__black",
      "href": "https://developers.weixin.qq.com/community/develop/doc/000242cccdc670f02ea977e105e409?highLine=%25E5%2585%25AC%25E4%25BC%2597%25E5%258F%25B7%25E5%25A6%2582%25E4%25BD%2595%25E5%2585%25B3%25E8%2581%2594%25E5%25B0%258F%25E7%25A8%258B%25E5%25BA%258F"
    }
  }, [_vm._v("公众号如何关联小程序？")])])]);

}]

export { render, staticRenderFns }